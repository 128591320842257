.home-center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
}

.home-coming-soon-image {
    align-self: center;
    width: 50%;
}