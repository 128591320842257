.footer-container {
    background-color: #282c34;
    position: absolute;
    height: 5em;
    width: 100%;
    bottom:0;

    display: flow;
    flex-direction: row;
    align-items: center;
    /*justify-content: center;*/
}


.footer-text {
    font-size: 1em;
    color: white;
    align-self: flex-start;
    padding: 0.2em
}

.footer-text-right {
    font-size: 1em;
    color: white;
    align-self: flex-end;
    padding: 0.2em;
}

.footer-text-short {
    display:none;
    font-size: 1em;
    color: white;
    align-self: flex-start;
    padding: 0.2em
}

.footer-text-right-short {
    display:none;
    font-size: 1em;
    color: white;
    align-self: flex-end;
    padding: 0.2em
}

@media(max-width: 600px){
    .footer-text-short {display: inline-block}
    .footer-text-right-short {display: inline-block}
    .footer-text{display: none}
    .footer-text-right{display: none}
    .footer-container {flex-direction: column}

}

.link:hover{
    color: lightblue;

}


.footer-left-container{
    float: left;
    margin-left: 1em;
    height: 100%;
    max-width: 50%;

    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-items: flex-end;
    justify-content: flex-start;

}

.footer-linkbox{
    float: left;
    height: 50%;
    max-width: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: flex-start;
}

.footer-right-container{
    float: right;
    margin-right: 1em;
    height: 100%;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    align-self: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
}