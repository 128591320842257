.module {
    padding-bottom: 0.5em;
}

.module-header {
    border-radius: 1em;

    border: 0.2em;
    border-color: #282c34;
    border-style: solid;

    background-color: lightgray;
}

.module-header-opened {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    background-color: #282c34;
    color: white;
}

.get-color {
    background-color: #e8f3fb;
}

.get-color-opened {
    background-color: #49b0f8;
}

.post-color {
    background-color: #e1f6f0;
}

.post-color-opened {
    background-color: #00cb93;
}

.put-color {
    background-color: #fdf0e6;
}

.put-color-opened {
    background-color: #ff9c48;
}

.patch-color {
    background-color: #e1f8f5;
}

.patch-color-opened {
    background-color: #00e3c2;
}

.delete-color {
    background-color: #fbe6e8;
}

.delete-color-opened {
    background-color: #ff3545;
}

.module-header-text {
    width: 100%;
    padding: 0.5em;
}

.module-header-text-shade {
    color: gray;
}

.module-content {
    border: 0.2em;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    border-color: #282c34;
    border-style: solid;
    border-top-style: none;
}

.module-content-item {
    padding: 0.5em;
}