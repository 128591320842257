.header-container {
    background-color: #282c34;

    width: 100%;
    height: 5em;

    position: fixed;

    z-index: 100;
}

.header-text {
    font-size: 1.2em;
    color: white;
}

.header-logo {
    height: 4em;
}

@media(max-width: 800px){
    .header-button{width: 1em}
}

.header-left-container {
    float: left;
    margin-left: 1em;
    height: 100%;
    max-width: 50%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.header-right-container {
    margin-right: 1em;
    height: 100%;
    max-width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header-button {
    height: 2.5em;
    width: 7.5em;

    margin-left: 1em;

    display: flex;
    align-items: center;
    justify-content: center;
}

.header-login-button {
    border: 0.2em;
    border-radius: 1em;
    border-color: white;
    border-style: solid;
}

.header-register-button {
    border: 0.2em;
    border-radius: 1em;
    border-color: white;
    border-style: solid;

    background-color: white;
}

.header-register-text {
    color: black;
}

.header-dropdown-background {
    background-color: #282c34;
    padding-bottom: 1em;

    margin-top: 5em;
    position: fixed;
    width: 100%;
}

.header-dropdown-button {
    border: 0.2em;
    border-radius: 1em;
    border-color: white;
    border-style: solid;

    width: 3em;
}

.header-dropdown-button-active {
    border: 0.2em;
    border-radius: 1em;
    border-color: white;
    border-style: solid;

    width: 3em;
    background-color: white;
}

.header-dropdown-item {
    margin-bottom: 0.5em;
}