.contact {
	width: 44%;
}

.full-length-contact {
	width: 94%;
}

.contact-form{
	display: inline;
}

#bericht {
	min-height: calc(1em * 15);
	font-family: Arial, Helvetica, sans-serif;
	resize: none;
}

.g-recaptcha {
	margin: 0.5em;
}

@media screen and (max-width:800px) {
	.contact {
		width: 94%;
	}
}